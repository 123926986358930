import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getFirstlogstatus, getToken } from './utils/utils';

import Spinner from './components/Spinner';
import AnalyticsDashboard from './pages/dashboard/AnalyticsDashboard';

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const AdminDashboard = lazy(() => import('./pages/dashboard/AdminDashboard'));
const AdminPage = lazy(() => import('./pages/dashboard/AdminPage'));
const Category = lazy(() => import('./pages/settings/category/Category'));
const CategoryOrder = lazy(() => import('./pages/settings/category-order/CategoryOrder'));
const CategoryType = lazy(() => import('./pages/settings/category-type/CategoryType'));

const AddFAQ = lazy(() => import('./pages/Content page/Addfaq.js'));

const Care = lazy(() => import('./pages/diacontent/DiaCareContent'));

const Update = lazy(() => import('./pages/updatepage/Updatepage.js'));

const Employee = lazy(() => import('./pages/employee/Employee'));
const EmployeeReporting = lazy(() => import('./pages/employeemapping/ReportingToMapping.js'));
const AddEmployee = lazy(() => import('./pages/employee/AddEmployee'));

const Profile = lazy(() => import('./pages/profile/Profile'));

const Shift = lazy(() => import('./pages/roaster/shift/Shift'));
const AddShift = lazy(() => import('./pages/roaster/shift/AddShift'));

const ShiftTime = lazy(() => import('./pages/roaster/shift-time/ShiftTime'));
const AddShiftTime = lazy(() => import('./pages/roaster/shift-time/AddShiftTime'));

const ShiftMapping = lazy(() => import('./pages/roaster/shift-mapping/ShiftMapping'));
const AddShiftMapping = lazy(() => import('./pages/roaster/shift-mapping/AddShiftMapping'));

const WeekOff = lazy(() => import('./pages/roaster/week-off/WeekOff'));
const AddWeekOff = lazy(() => import('./pages/roaster/week-off/AddWeekOff'));

const DoctorCalender = lazy(() => import('./pages/roaster/doctor-calender/DoctorCalender'));

const AddCompany = lazy(() => import('./pages/company/company/AddCompany'));
const Company = lazy(() => import('./pages/company/company/Company'));

const AddBranch = lazy(() => import('./pages/company/branch/AddBranch'));
const Branch = lazy(() => import('./pages/company/branch/Branch'));

const CodPincode = lazy(() => import('./pages/codappreval/CodPincode'));
const Cod = lazy(() => import('./pages/codappreval/Codapprove'));
const Codpaylater = lazy(() => import('./pages/codappreval/Paylaterorders.js'));
const order = lazy(() => import('./pages/codappreval/Orderstatus'));
const CodBank = lazy(() => import('./pages/codappreval/CodBankInfo'));

const WalletandCoins = lazy(() => import('./pages/wallet-and-coins/Wallet-and-Coins-Rulepage.js'));
const WalletandCoinsRules = lazy(() => import('./pages/wallet-and-coins/WalledandcoinRules.js'));

const Banner = lazy(() => import('./pages/banner/Banner'));
const ViewBanner = lazy(() => import('./pages/banner/Viewbanner'));

const RoleMapping = lazy(() => import('./pages/role-management/role-mapping/RoleMapping'));

const AddSlot = lazy(() => import('./pages/roaster/slot/AddSlot'));
const Slot = lazy(() => import('./pages/roaster/slot/Slot'));

const Leave = lazy(() => import('./pages/roaster/leave/Leave'));
const AddLeave = lazy(() => import('./pages/roaster/leave/Addleave'));
const Leaverequest = lazy(() => import('./pages/roaster/leave/Leaverequest'));
const TeamLeavehistory = lazy(() => import('./pages/roaster/leave/TeamLeavehistory'));
const LeaveApproval = lazy(() => import('./pages/roaster/leave/LeaveApproval'));
const AllLeave = lazy(() => import('./pages/roaster/leave/Allleave'));
const Hrleave = lazy(() => import('./pages/roaster/leave/Hrteamleave'));
const Leavetype = lazy(() => import('./pages/roaster/leave/Leavetype'));
const Holiday = lazy(() => import('./pages/roaster/leave/Holiday'));
const CreateLeaveBalance = lazy(() => import('./pages/roaster/leave/CreateLeaveBalance'));
const LeaveMap = lazy(() => import('./pages/roaster/leave/Leavemapping'));
const Compoff = lazy(() => import('./pages/roaster/leave/Compoff'));

const Timetrack = lazy(() => import('./pages/roaster/mangetime/Timetracking'));
const Timeatten = lazy(() => import('./pages/roaster/mangetime/Viewatten'));
const Multilog = lazy(() => import('./pages/roaster/mangetime/Multiplelogs'));
const upload = lazy(() => import('./pages/roaster/mangetime/Attendanceupload'));
const DepartmentLeave = lazy(() => import('./pages/roaster/mangetime/DepartmentLeave'));
const Attendance = lazy(() => import('./pages/roaster/mangetime/Attendancesummery'));

const Reports = lazy(() => import('./pages/reports/ReportTemplate'));

const SendMassage = lazy(() => import('./pages/patient-profile/SendThePatientMassage'));

const Roles = lazy(() => import('./pages/role-management/role-mapping/Roles'));
const RolesResponsibility = lazy(() => import('./pages/role-management/role-mapping/RolesResponsibility'));
const CreateTeamOwner = lazy(() => import('./pages/referral/CreateTeamOwner'));
const ManageTeam = lazy(() => import('./pages/referral/ManageTeam'));
const AddReferralMapping = lazy(() => import('./pages/referral/AddReferralMapping'));

const ReferralLink = lazy(() => import('./pages/refer-link/ReferLink'));
const ReferralDoctor = lazy(() => import('./pages/refer-doctor/ReferDoctor'));
const ReferralRequest = lazy(() => import('./pages/referral/ReferralRequest'));

const PharmaDrugType = lazy(() => import('./pages/pharmacy/drug-type/DrugType'));
const PharmaPack = lazy(() => import('./pages/pharmacy/pack/Pack'));
const PharmaProductType = lazy(() => import('./pages/pharmacy/product-type/ProductType'));
const PharmaSchedule = lazy(() => import('./pages/pharmacy/schedule/Schedule'));
const PharmaDrug = lazy(() => import('./pages/pharmacy/drug/Drug'));
const PharmacyOrders = lazy(() => import('./pages/orders/PharmacyOrders'));
const LabOrders = lazy(() => import('./pages/orders/LabOrders'));
const ConsultationOrders = lazy(() => import('./pages/orders/ConsultationOrders'));
const MailOrders = lazy(() => import('./pages/orders/mailOrders/MailOrders'));
const CheckPaid = lazy(() => import('./pages/checkpaidornot_paid/ChaeckPaidOrNotPaid'));

const SkillTypes = lazy(() => import('./pages/skills/Skilltypes'));
const SkillLevels = lazy(() => import('./pages/skills/SkillLevels'));
const SkillSets = lazy(() => import('./pages/skills/SkillSets'));
const SkillMaps = lazy(() => import('./pages/skills/SkillMaps'));

const Dietary = lazy(() => import('./pages/Dietarydata/Dietarydata'));

const Coupons = lazy(() => import('./pages/coupons/Coupon'));
const Subscription = lazy(() => import('./pages/subscription/Subscription'));
const Appointment = lazy(() => import('./pages/documentpreration/AppointmentLetter'));
const Relieving = lazy(() => import('./pages/documentpreration/RelievingLetter'));
const Offer = lazy(() => import('./pages/documentpreration/Offerletter'));

const Change = lazy(() => import('./pages/chnage/changepassword'));

const PatientProfile = lazy(() => import('./pages/patient-profile/patientProfile'));
const PatientProfileAR = lazy(() => import('./pages/patient-profile/PatientARnoEdit'));
const SendOffer = lazy(() => import('./pages/patient-profile/SendOfferNotification'));

const Error404 = lazy(() => import('./pages/error-pages/Error404'));
const Error500 = lazy(() => import('./pages/error-pages/Error500'));
const Reset = lazy(() => import('./pages/reset/Resetpassword'));
const Login = lazy(() => import('./pages/user-pages/Login'));

const BlankPage = lazy(() => import('./pages/general-pages/BlankPage'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/adminDashboard" component={AdminDashboard} />
          <Route exact path="/dashboard/adminPage" component={AdminPage} />
          <Route exact path="/dashboard/analyticsDashboard" component={AnalyticsDashboard} />
          <Route exact path="/settings/category" component={Category} />
          <Route exact path="/settings/category-type" component={CategoryType} />
          <Route exact path="/settings/category-order" component={CategoryOrder} />
          <Route exact path="/orders/check" component={CheckPaid} />

          <Route exact path="/content/addfaq" component={AddFAQ} />

          <Route exact path="/patient/message" component={SendMassage} />

          <Route exact path="/care/content" component={Care} />

          <Route exact path="/coin/Walletandcoins" component={WalletandCoins} />
          <Route exact path="/coin/rules" component={WalletandCoinsRules} />

          <Route exact path="/update" component={Update} />

          <Route exact path="/cod/pincode" component={CodPincode} />
          <Route exact path="/cod/approval" component={Cod} />
          <Route exact path="/cod/paylater" component={Codpaylater} />
          <Route exact path="/cod/Orderstatus" component={order} />
          <Route exact path="/cod/bankinfo" component={CodBank} />
          <Route exact path="/banner" component={Banner} />
          <Route exact path="/view/banner" component={ViewBanner} />
          <Route exact path="/employee/manage-employee" component={Employee} />
          <Route exact path="/employee/mapping" component={EmployeeReporting} />
          <Route exact path="/employee/add-employee" component={AddEmployee} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/roaster/add-shift" component={AddShift} />
          <Route exact path="/roaster/shift" component={Shift} />
          <Route exact path="/roaster/shift-time" component={ShiftTime} />
          <Route exact path="/roaster/add-shift-time" component={AddShiftTime} />
          <Route exact path="/roaster/add-shift-mapping" component={AddShiftMapping} />
          <Route exact path="/roaster/shift-mapping" component={ShiftMapping} />
          <Route exact path="/roaster/week-off" component={WeekOff} />
          <Route exact path="/roaster/add-week-off" component={AddWeekOff} />
          <Route exact path="/roaster/doctor-calender" component={DoctorCalender} />
          <Route exact path="/leave/balance" component={Leave} />
          <Route exact path="/leave/addleave" component={AddLeave} />
          <Route exact path="/leave/histroy" component={Leaverequest} />
          <Route exact path="/leave/teamleave/histroy" component={TeamLeavehistory} />
          <Route exact path="/leave/approval" component={LeaveApproval} />
          <Route exact path="/leave/allleave" component={AllLeave} />
          <Route exact path="/leave/hrteamleave" component={Hrleave} />
          <Route exact path="/leave/leavetype" component={Leavetype} />
          <Route exact path="/leave/holiday" component={Holiday} />
          <Route exact path="/leave/createLeaveBalance" component={CreateLeaveBalance} />
          <Route exact path="/leave/leavemap" component={LeaveMap} />
          <Route exact path="/leave/compoff" component={Compoff} />
          <Route exact path="/time/track" component={Timetrack} />
          <Route exact path="/time/attendance" component={Timeatten} />
          <Route exact path="/time/multilogs" component={Multilog} />
          <Route exact path="/time/attendance/upload" component={upload} />
          <Route exact path="/time/departmentattendance" component={DepartmentLeave} />
          <Route exact path="/time/summary" component={Attendance} />
          <Route exact path="/company/add-company" component={AddCompany} />
          <Route exact path="/company/company" component={Company} />
          <Route exact path="/company/branch" component={Branch} />
          <Route exact path="/company/add-branch" component={AddBranch} />
          <Route exact path="/roaster/slot" component={Slot} />
          <Route exact path="/roaster/add-slot" component={AddSlot} />
          <Route exact path="/role/role-mapping" component={RoleMapping} />
          <Route exact path="/role/roles" component={Roles} />
          <Route exact path="/role/responsibility" component={RolesResponsibility} />
          <Route exact path="/referral/CreateTeamOwner" component={CreateTeamOwner} />
          <Route exact path="/referral/manageTeam" component={ManageTeam} />
          <Route exact path="/referral/referralStatus" component={ReferralRequest} />
          <Route exact path="/referral/add-referral-mapping" component={AddReferralMapping} />
          <Route exact path="/referral-link" component={ReferralLink} />
          <Route exact path="/referral-doctor" component={ReferralDoctor} />
          <Route exact path="/dietarydata" component={Dietary} />

          <Route exact path="/orders/labOrders" component={LabOrders} />
          <Route exact path="/orders/pharmacyOrders" component={PharmacyOrders} />
          <Route exact path="/orders/consultationOrders" component={ConsultationOrders} />
          <Route exact path="/orders/mails" component={MailOrders} />
          <Route exact path="/pharmacy/drug" component={PharmaDrug} />
          <Route exact path="/pharmacy/drug-type" component={PharmaDrugType} />
          <Route exact path="/pharmacy/drug-pack" component={PharmaPack} />
          <Route exact path="/pharmacy/drug-product-type" component={PharmaProductType} />
          <Route exact path="/pharmacy/drug-schedule" component={PharmaSchedule} />

          <Route exact path="/report" component={Reports} />

          <Route exact path="/skill/types" component={SkillTypes} />
          <Route exact path="/skill/levels" component={SkillLevels} />
          <Route exact path="/skill/sets" component={SkillSets} />
          <Route exact path="/skill/maps" component={SkillMaps} />

          <Route exact path="/coupons" component={Coupons} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/document/appointmentletter" component={Appointment} />
          <Route exact path="/document/relievingletter" component={Relieving} />
          <Route exact path="/document/offerletter" component={Offer} />
          <Route exact path="/changepassword" component={Change} />

          <Route exact path="/patient/profile" component={PatientProfile} />
          <Route exact path="/patient/arno" component={PatientProfileAR} />
          <Route exact path="/patient/sendOffer" component={SendOffer} />

          <Route path="/login" component={Login} />
          <Route path="/resetpassword" component={Reset} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/general-pages/blank-page" component={BlankPage} />
          {getToken() && getFirstlogstatus() === false ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
