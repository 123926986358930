export const labReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_LAB_DATA':
      return {
        ...state,
        [action.path]: action.payload,
      };
    case 'GET_SUBGROUP_BY_GROUP':
      return {
        ...state,
        subgroupByGroupId: action.payload,
      };
    case 'GET_LAB_EXECUTIVE_DATA':
      return {
        ...state,
        labExecutiveByLabId: action.payload,
      };
    case 'GET_LAB_AVAIL_SLOTS_DATE':
      return {
        ...state,
        labAvailableSlotsByDate: action.payload,
      };
    case 'GET_ASSIGN_EXECUTIVE_DATA':
      return {
        ...state,
        labExecutiveByLabIdAndPincode: action.payload,
      };
    case 'GET_LAB_DETAILS_BY_BOOKINGID':
      return {
        ...state,
        labDetailsByBookingId: action.payload,
      };
    default:
      return state;
  }
};
export default labReducer;
