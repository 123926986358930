export const globalReducer = (
  state = {
    overlayLoading: false,
    overlaypage: false,
    filePriview: {
      fileOpen: false,
      fileType: null,
      signedUrl: null,
    },
  },
  action,
) => {
  switch (action.type) {
    case 'SET_TOKEN_VALID':
      return {
        tokenValid: action.payload,
      };
    case 'FILE_PRIVIEW':
      return {
        ...state,
        filePriview: action.payload,
      };
    case 'GET_OFFER':
      return {
        offerData: action.payload,
      };
    case 'GET_STATE_DATA':
      return {
        ...state,
        states: action.payload,
      };
    case 'GET_USE_COINDATA':
      return {
        ...state,
        usedata: action.payload,
      };
    case 'GET_COINS':
      return {
        ...state,
        coinsdata: action.payload,
      };
    case 'GET_COINS_RULE':
      return {
        ...state,
        coinsrule: action.payload,
      };
    case 'GET_WALLET_RULE':
      return {
        ...state,
        walletrule: action.payload,
      };
    case 'GET_SUB_DATA':
      return {
        ...state,
        subscriptionall: action.payload,
      };
    case 'GET_CATEGORY_SEARCH':
      return {
        ...state,
        searchitems: action.payload,
      };
    case 'GET_DEPOSIT_DATA':
      return {
        ...state,
        deposit: action.payload,
      };
    case 'DASHBOARD_OPTION':
      return {
        ...state,
        dashboardOption: action.payload,
      };
    case 'REPORT_OPTION':
      return {
        ...state,
        reportOption: action.payload,
      };
    case 'GET_CARE_DATA':
      return {
        ...state,
        carecontent: action.payload,
      };
    case 'GET_COMPOFF_DATA':
      return {
        ...state,
        comp: action.payload,
      };
    case 'GET_CITY_DATA':
      return {
        ...state,
        cities: action.payload,
      };
    case 'GET_ZONE_DATA':
      return {
        ...state,
        zones: action.payload,
      };
    case 'GET_FUNCTIONS_DATA':
      return {
        ...state,
        functions: action.payload,
      };
    case 'GLOBLE_OVERLOADING':
      return {
        ...state,
        overlayLoading: action.payload,
      };
    case 'GLOBLE_LOADING':
      return {
        ...state,
        overlaypage: action.payload,
      };
    case 'GET_SUBSCRIPTION_DATA':
      return {
        ...state,
        subscriptionData: action.payload,
      };
    case 'GET_CITY_FEES':
      return {
        ...state,
        [action.name]: action.payload,
      };
    case 'SUBMIT_BUTTON':
      return {
        ...state,
        submitButton: action.payload,
      };
    case 'GET_RECORD_DATA':
      return {
        ...state,
        recordlog: action.payload,
      };
    default:
      return state;
  }
};
export default globalReducer;
