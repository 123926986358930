export const patientProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PATIENTPROFILE_DATA':
      return {
        ...state,
        [action.path]: action.payload,
      };
    case 'GET_PRO_DATA':
      return {
        ...state,
        profile: action.payload,
      };
    case 'GET_PATIENT_PACKAGES':
      return {
        ...state,
        patientPackages: action.payload,
      };
    case 'GET_PATIENT_SUBSCRIPTION':
      return {
        ...state,
        patientSubscription: action.payload,
      };
    case 'GET_PATIENT_UPCOMING_CONSULTAION':
      return {
        ...state,
        patientUpComingConsultation: action.payload,
      };
    case 'GET_PATIENT_BY_ID':
      return {
        ...state,
        getPatientById: action.payload,
      };
    case 'GET_PATIENTADDRESSDATA_LIST':
      return {
        ...state,
        getPatientAddress: action.payload,
      };
    default:
      return state;
  }
};
export default patientProfileReducer;
