export const roasterReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_SHIFTTIME_DATA':
      return {
        ...state,
        shifttime: action.payload,
      };
    case 'GET_SHIFTTIMEMAP_DATA':
      return {
        ...state,
        shiftTimeMap: action.payload,
      };
    case 'GET_VIEWSHIFT_DATA':
      return {
        ...state,
        shiftview: action.payload,
      };
    case 'GET_LEAVEMASTER_DATA':
      return {
        ...state,
        leavemaster: action.payload,
      };
    case 'GET_DIETARY_DATA':
      return {
        ...state,
        dietarydata: action.payload,
      };
    case 'GET_HOLIDAY_DATA':
      return {
        ...state,
        holidaymaster: action.payload,
      };
    case 'GET_DOCTOR_SLOT_DURATION_DATA':
      return {
        ...state,
        slotDuration: action.payload,
      };
    default:
      return state;
  }
};
export default roasterReducer;
