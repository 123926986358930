/* eslint-disable no-unneeded-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable node/handle-callback-err */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { getToken, getUsername, removeToken } from '../utils/utils';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { loadingAction } from '../redux/actions/actions';
// import { REACT_APP_HRMS_SERVICE_URL } from '../config';
const baseURL = process.env.REACT_APP_BASE_URL + '/diahome/v1/api/hrms-service';
const baseURLADMIN = process.env.REACT_APP_BASE_URL + '/diahome/v1/api/admin-service';
const baseURLPATIENT = process.env.REACT_APP_BASE_URL + '/diahome/v1/api/patient-service';
const baseURLLAB = process.env.REACT_APP_BASE_URL + '/diahome/v2/api/laboratory-service';
const baseURLPHARMACY = process.env.REACT_APP_BASE_URL + '/diahome/v1/api/pharmacy-service';
// const authURL = process.env.REACT_APP_AUTH_SERVICE_URL;
// const baseURL = process.env.REACT_APP_FILE_SERVICE_URL;
// const labURL = process.env.REACT_APP_LAB_SERVICE_URL;

const headers = () => {
  return {
    applicationLabel: 'HRMS-Portal',
    correlationId: uuidv4(),
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };
};
const headersADMIN = () => {
  return {
    applicationLabel: 'Admin-Portal',
    correlationId: uuidv4(),
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };
};
const headersPatient = () => {
  return {
    applicationLabel: 'Patient-Web',
    correlationId: uuidv4(),
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };
};
const headerslab = () => {
  return {
    applicationLabel: 'Lab-Portal',
    correlationId: uuidv4(),
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };
};
const headerphar = () => {
  return {
    applicationLabel: 'Pharmacy-Portal',
    correlationId: uuidv4(),
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };
};
// const labheaders = () => {
//   return {
//     applicationLabel: 'Lab-Portal',
//     correlationId: uuidv4(),
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${getToken()}`,
//   };
// };
const triggerToastSuccess = (message) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
const triggerToastError = (message) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const logoutService = (props) => {
  props.history.push({
    pathname: '/login',
    state: {},
  });
  return removeToken();
};
export const errormsg = (error, props) => {
  console.log(error, 'error');
  console.log(error.response, 'error response');
  if (error && error.response?.status === 417) {
    return triggerToastError(`${error.response?.data?.message}`);
  }
  if (error && error.response?.status === 404) {
    triggerToastError(`Not found`);
  } else if (error && error.response?.status === 401) {
    console.log(error.response);
    const err = error.response.data.errors;
    err.map((value) => {
      triggerToastError(`${value.message}`);
    });
    if (props) logoutService(props);
    console.log(props);

    // <Redirect to="/login" />;
  } else if (error && error.response?.status >= 500) {
    triggerToastError(`Server Error`);
  } else if (error && error.response && error.response.data.errors?.length > 0) {
    const err = error.response.data.errors;
    err.map((value) => {
      triggerToastError(`${value.message}`);
    });
  } else {
    triggerToastError(`${error && error.response && error.response.message}`);
  }
};
export const sorreApi = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURLPATIENT}/profiles/jan-month/data/`,
    headers: headersPatient(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const profileDetails = async (d, props) => {
  const data = {
    emailAddress: d,
  };
  const config = {
    method: 'post',
    url: `${baseURL}/employeebyemail/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        localStorage.setItem('empId', response.data.data[0].empId);
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getEmployeeId = async (d, props) => {
  const data = {
    emailAddress: d,
  };
  const config = {
    method: 'post',
    url: `${baseURL}/employeebyemail/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        localStorage.setItem('empId', response.data.data[0].empId);
        recordOfLoginandLogout({ empId: response.data.data[0].empId, entryType: 'login' });
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const login = async (d, dispatch) => {
  const data = JSON.stringify(d);
  console.log(getToken(), 'token');
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/diahome/v1/api/admin-service/login`,
    headers: {
      applicationLabel: 'Admin-Portal',
      correlationId: uuidv4(),
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        dispatch({
          type: 'LOGOUT_PROOF',
          payload: false,
        });
        localStorage.setItem('accessToken', response.data.data.accessToken);
        localStorage.setItem('firstlog', response.data.data.firstLogin);
        localStorage.setItem('email', response.data.data.email);
        localStorage.setItem('roles', response.data.data.roles);
        localStorage.setItem('username', response.data.data.username);
        localStorage.setItem('functions', response.data.data.functions);
        localStorage.setItem('loginDuration', response.data.data.loginDuration);
        profileDetails(response.data.data.email);
        getEmployeeId(response.data.data.email);

        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error);
      dispatch(loadingAction(false));
    });
};
export const recordOfLoginandLogout = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/record-attendance/create/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const logout = async (data) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/diahome/v1/api/admin-service/logout/`,
    headers: {
      applicationLabel: 'Admin-Portal',
      correlationId: uuidv4(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        triggerToastSuccess(response.data.data.message);
        // dispatch({
        //   type: 'LOGOUT_PROOF',
        //   payload: true,
        // });
        // localStorage.setItem('accessToken', response.data.data.accessToken);
        // localStorage.setItem('firstlog', response.data.data.firstLogin);
        // localStorage.setItem('email', response.data.data.email);
        // localStorage.setItem('roles', response.data.data.roles);
        // localStorage.setItem('username', response.data.data.username);
        // localStorage.setItem('functions', response.data.data.functions);
        // profileDetails(response.data.data.email);
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const forgetpasshr = (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/forgot-password/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const Resetpass = (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/reset-password/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response.data.message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const Changepass = (data, changePasswordSuccess, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/changepassword/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      if (changePasswordSuccess) changePasswordSuccess();
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const verifyOtphr = (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/verify-otp/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getCategoryList = (categoryType, sort) => {
  let sortInstruction;
  if (sort === 'priority') {
    sortInstruction = {
      attributeName: 'priority',
      sortDirection: 1,
    };
  } else {
    sortInstruction = undefined;
  }
  const data = JSON.stringify({
    searchCriteria: {
      categoryType: categoryType,
      isActive: true,
    },
    sortInstruction: sortInstruction,
  });

  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/findall`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};

export const getHospital = (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/hospital/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getApp = (props) => {
  const config = {
    method: 'get',
    url: `${baseURLADMIN}/masters/app-info/all/constants/`,
    headers: headersADMIN(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getupdateddata = (props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/app-info/all/list/`,
    headers: headersADMIN(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createAppinfo = async (_d, method, path, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURLADMIN}${path}`,
    headers: headersADMIN(),
    data: data,
  };

  await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getBranch = (props, dispatch) => {
  const config = {
    method: 'get',
    url: `${baseURL}/branch/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      dispatch(loadingAction(false));
      return response.data.data;
    })
    .catch(function (error) {
      dispatch(loadingAction(false));
      errormsg(error, props);
    });
};

export const getState = (props) => {
  const data = JSON.stringify({
    searchCriteria: {},
  });

  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/states/findall`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getCity = (stateCodeName, props) => {
  // const data = JSON.stringify({
  //   searchCriteria: { stateCodeName: stateCodeName },
  // });

  const config = {
    method: 'GET',
    url: `${baseURLADMIN}/masters/cities/find-by-state/${stateCodeName}`,
    headers: headersADMIN(),
    // data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const createEmployee = (_d, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseURL}/employee/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createReferralDoctor = (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseURL}/referred/`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getReferralRequest = (d, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/referrals/teams/${d}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getReferralRequestByEmp = (d, props) => {
  const data = {
    searchCriteria: {
      status: d,
      updatedBy: localStorage.getItem('empId'),
    },
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/referrals/teams/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateEmployee = (_d, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'put',
    url: `${baseURL}/employee/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const generateUser = (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseURL}/employee/generate-user/`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const roleUpdateEmployee = async (_d, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'put',
    url: `${baseURL}/employee/roles/`, // id, empId, roles
    headers: headers(),
    data: data,
  };

  await axios(config)
    .then(function (response) {
      // if (generateUserInd) generateUser(response.data.data);
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const listEmployee = (d, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/listEmployee/`,
    headers: headers(),
    data: d,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const cancelConsultation = (d, id, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/consultations/${id}/cancel-consultation`,
    headers: headers(),
    data: d,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getSchedule = (doctorId, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/roaster/schedule-masters?doctorId=${doctorId}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getSlots = (doctorId, date, props) => {
  const formetDate = moment(date).format('YYYY-MM-DD');
  const config = {
    method: 'get',
    url: `${baseURL}/roaster/slots-by-date?doctorId=${doctorId}&date=${formetDate}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const deleteSlot = async (data, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/roaster/delete-slot`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.data.message;
      triggerToastSuccess(msg);
      return response.data;
    })
    .catch(function (error) {
      // if (error && error.response && error.response.data.errors[0].message) {
      //   triggerToastError(`${error && error.response && error.response.data.errors[0].message}`);
      // } else {
      //   triggerToastError(`${error && error.response && error.response.message}`);
      // }
      // return error.response;
      errormsg(error, props);
    });
};
export const openSlot = async (data, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/roaster/open-slot`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.data.message;
      triggerToastSuccess(msg);
      return response.data;
    })
    .catch(function (error) {
      // if (error && error.response && error.response.data.errors[0].message) {
      //   triggerToastError(`${error && error.response && error.response.data.errors[0].message}`);
      // } else {
      //   triggerToastError(`${error && error.response && error.response.message}`);
      // }
      // return error.response;

      errormsg(error, props);
    });
};
export const getSlotDuration = () => {
  const config = {
    method: 'get',
    url: `${baseURL}/roaster/slot/duration/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {});
};
export const getEmployee = (status = 'true', sortBy = '-empId', props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/employee/?empStatus=${status}&sortBy=${sortBy}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getEmployeeWithFilter = (status = 'true') => {
  const result = {
    itemsPerPage: 0,
    pageNumber: 0,
    searchCriteria: {
      empStatus: status,
    },
    sortInstruction: {
      attributeName: '-empId',
      sortDirection: '-1',
    },
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/listEmployee/`,
    headers: headers(),
    data: result,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data.items;
    })
    .catch(function (error) {});
};
export const getRoles = () => {
  const config = {
    method: 'get',
    url: `${baseURLADMIN}/masters/roles`,
    headers: headersADMIN(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {});
};
export const getRolesFunctionalityList = (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/roles/functions`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getRolesList = (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/roles/listRoles`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createRole = (_d, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}/roles`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getReferral = () => {
  const config = {
    method: 'get',
    url: `${baseURL}/referrals/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const createReferral = (_d, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}/referrals/`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const createReferralMapping = (_d, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}/referrals/mappings`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getReferralMappings = () => {
  const config = {
    method: 'get',
    url: `${baseURL}/referrals/mappings`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const createShiftTime = (_d, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}/roaster/shift`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getShifttime = (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/roaster/shift`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getShiftTimeMap = () => {
  const config = {
    method: 'get',
    url: `${baseURL}/roaster/listshifts`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const allteammembers = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/referrals/all-team-member`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createHospital = async (_d, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}/hospital/`,
    headers: headers(),
    data: data,
  };

  await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const createBranch = (_d, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}/branch/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const createScheduleMaster = async (_d, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseURL}/roaster/schedule-masters`,
    headers: headers(),
    data: data,
  };

  await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateScheduleMaster = (_d, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'put',
    url: `${baseURL}/roaster/schedule-masters`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const updateActiveInactiveStatus = async (_d, path, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const ReportCall = async (_d, path, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      console.log(message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateActiveInactiveStatusPatientService = async (_d, path, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}/patients/${path}`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      return error.response;
    });
};

export const getPharmaCategory = (path) => {
  const config = {
    method: 'get',
    url: `${baseURL}/drug/${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const createPharmaCategory = (path, method, data) => {
  const config = {
    method: method,
    url: `${baseURL}/drug/${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};

export const createAdminCategoryType = (method, data, props) => {
  const config = {
    method: method,
    url: `${baseURLADMIN}/masters/categories/types`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createAdminCategory = (method, data, props) => {
  const config = {
    method: method,
    url: `${baseURLADMIN}/masters/categories`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateOrderAdminCategory = (method, data, props) => {
  const config = {
    method: method,
    url: `${baseURLADMIN}/masters/categories/list-update/`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAdminCategoryType = (status, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/types/findall`,
    headers: headersADMIN(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
      searchCriteria: {
        isActive: status,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAdminCategory = (categoryType, isActive, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/findall`,
    headers: headersADMIN(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
      searchCriteria: {
        categoryType: categoryType,
        isActive: isActive,
      },
      sortInstruction: {
        attributeName: 'priority',
        sortDirection: 1,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getfood = (categoryType, isActive, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/findall`,
    headers: headersADMIN(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
      searchCriteria: {
        categoryType: categoryType,
        isActive: isActive,
      },
      sortInstruction: {
        attributeName: 'priority',
        sortDirection: 1,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getparentcode = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURLADMIN}/masters/categories/${id}`,
    headers: headersADMIN(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getCategoryempmapping = (categoryType, name, isActive, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/findall`,
    headers: headersADMIN(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
      searchCriteria: {
        categoryType: categoryType,
        parentCodeName: name,
        isActive: isActive,
      },
      sortInstruction: {
        attributeName: 'priority',
        sortDirection: 1,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getLabData = (path, props) => {
  let pathUrl;
  if (path) {
    pathUrl = 'laboratory/lab-masters/' + path + '/';
  } else {
    pathUrl = 'laboratory/laboratory/' + path;
  }
  if (path === 'packages') {
    pathUrl = 'laboratory/' + path;
  }
  const config = {
    method: 'get',
    url: `${baseURL}/${pathUrl}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getLabTestData = (data, itemPerPage, pageNumber, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/laboratory/lab-masters/findall/`,
    headers: headers(),
    data: { searchCriteria: data, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getLabPackageData = (data, itemPerPage, pageNumber, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/laboratory/packages/all/list/`,
    headers: headers(),
    data: { searchCriteria: data, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getinactivePackagesByPatientIdService = (patientId, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/laboratory/laboratory/bookings/get-my-packages/${patientId}/?allPackages=true`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const drugService = (methord, data, id, props, url) => async (dispatch) => {
  console.log(id, 'id');
  if (id) {
    id = id + '/';
  }
  const config = {
    method: methord,
    url: `${baseURL}/pharmacy/drugs/${url || id}`,
    headers: headers(),
    data: methord === 'DELETE' || methord === 'GET' ? undefined : data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
    });
};
export const getSubscriptionService = (props) => async (dispatch) => {
  const config = {
    method: 'GET',
    url: `${baseURLADMIN}/subscription/all-subscription/`,
    headers: headersADMIN(),
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
    });
};
export const getSubgroupByGroupId = (path) => {
  const config = {
    method: 'get',
    url: `${baseURL}${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const sendMailTothepatient = (data, path) => {
  const config = {
    method: 'post',
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.date;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const CreatepatientProfile = (method, data, path) => {
  const config = {
    method: method,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.date;
    })
    .catch(function (error) {
      errormsg(error);
      return error.response;
    });
};
export const sendMailTothepatientLab = (data, path) => {
  const config = {
    method: 'post',
    url: `${baseURLLAB}${path}`,
    headers: headerslab(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.date;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const sendMailTothepatientPharmacy = (data, path) => {
  const config = {
    method: 'post',
    url: `${baseURLPHARMACY}${path}`,
    headers: headerphar(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.date;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};

export const createLabData = (path, method, data) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: method,
    url: `${baseURL}/laboratory/${pathUrl}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.errors[0]}`);
      return error.response;
    });
};

export const getSkillsData = (path, props) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: 'get',
    url: `${baseURL}/skills/${pathUrl}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const modifySkillsData = (path, method, data, props) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: method,
    url: `${baseURL}/skills/${pathUrl}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getCouponData = (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/coupons`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const modifyCouponData = (path, method, data, props) => {
  const config = {
    method: method,
    url: `${baseURL}/coupons/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response?.data?.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response?.data?.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getServicePincodeByLab = (path) => {
  const config = {
    method: 'get',
    url: `${baseURL}${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};

export const getLabBookings = (status, labId, pageNumber, itemsPerPage) => {
  const config = {
    method: 'post',
    url: `${baseURL}/laboratory/bookings/findall`,
    headers: headers(),
    data: {
      pageNumber: pageNumber,
      itemsPerPage: itemsPerPage,
      searchCriteria: {
        status: status,
        labId: labId,
      },
      sortInstruction: {
        attributeName: 'date',
        sortDirection: -1,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const createServicePincodeByLab = (path, method, data) => {
  const config = {
    method: method,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const compoffleaverequest = async (data) => {
  const config = {
    method: 'post',
    url: `${baseURL}/attendance/emp/status/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const createcarecontent = async (data, meth, url, props) => {
  const config = {
    method: meth,
    url: `${baseURLADMIN}/masters/diet-care-content/${url}`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAllcarecontent = async (meth, data, props) => {
  const config = {
    method: meth,
    url: `${baseURLADMIN}/masters/diet-care-content/all/list/`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getcarecontent = async (meth, data, props) => {
  const config = {
    method: meth,
    url: `${baseURLADMIN}/masters/diet-care-content/${data}/`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateOrderLabPincodes = (method, data) => {
  const config = {
    method: method,
    url: `${baseURL}/laboratory/services/update-priority/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};

export const updateLabBookingStatus = (method, bookingId, status) => {
  if (bookingId && bookingId.length > 0 && status) {
    const data = bookingId.map((b) => {
      return {
        status: status,
        description: '',
        message: '',
        bookingId: b,
        updatedBy: getUsername(),
      };
    });
    const config = {
      method: method,
      url: `${baseURL}/laboratory/bookings/set-status/`,
      headers: headers(),
      data: data,
    };

    return axios(config)
      .then(function (response) {
        const msg = response.data.message;
        const message =
          msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
        triggerToastSuccess(message);
        return response.data;
      })
      .catch(function (error) {
        triggerToastError(`${error.response.data.message}`);
        return error.response;
      });
  } else {
    triggerToastError('Please select atleast one Booking and status');
  }
};
export const getPatientProfileData = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/profiles/all/list/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendOffer = (data, method, path, props) => {
  const config = {
    method: method,
    url: `${baseURLADMIN}/notifications${path}`,
    headers: headersADMIN(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPackagesByPatientIdService = (patientId, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/laboratory/laboratory/bookings/get-my-packages/${patientId}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getUpComingConsulationByPatientIdService = (patientId, data1, pageNumber, itemPerPage, props) => {
  // eslint-disable-next-line no-unneeded-ternary
  const data = {
    searchCriteria: { patientId: patientId, status: data1 ? data1 : 'SCHEDULED' },
    itemsPerPage: itemPerPage ? itemPerPage : 5,
    pageNumber: pageNumber ? pageNumber : 1,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/patients/consultations/findall`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientByIdService = (patientId, props) => {
  const data = {
    userId: patientId,
    familyMembers: true,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/patients/profiles/get/primary-user/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientAddressDataListService = async (patientId, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/patients/profiles/${patientId}/addresses`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const submitSwapPatientService = (patientId, casesheetId, address, props) => {
  const data = {
    location: {
      latitude: address,
    },
  };
  const config = {
    method: 'PUT',
    url: `${baseURL}/patients/consultations/${casesheetId}/swap/${patientId}`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const submitSwapDoctorService = (empId, casesheetId, props) => {
  // const data = {
  //   location: {
  //     latitude: address,
  //   },
  // };
  const config = {
    method: 'post',
    url: `${baseURL}/patients/consultations/${casesheetId}/doctor-swap/${empId}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getSubscriptionPatientIdService = (patientId, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/patients/subscriptions/detail-patient/`,
    headers: headers(),
    data: {
      patientId: patientId,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const modifyPatientProfile = (method, data, props) => {
  const config = {
    method: method,
    url: `${baseURL}/patients/profiles/referral/update/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response?.data?.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response?.data?.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const amountorder = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/home-collection/cod/consultation/get-order/consultation/${id}/`,
    headers: headers(),
    // data: data,
  };

  return axios(config)
    .then(function (response) {
      // const msg = response?.data?.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      return response?.data?.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const createSlotDuration = (path, method, data) => {
  const config = {
    method: method,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response?.data?.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response?.data?.data;
    })
    .catch(function (error) {
      triggerToastError(`${error?.response?.data?.message}`);
      return error?.response;
    });
};
export const fileAttachmentUploadURLGen = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: headers(),
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
            if (response.status === 200) return fileUrl;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {}
};
export const fileAttachmentUploadURL = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: headers(),
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
            if (response.status === 200) return fileUrl;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err);
      });
  } catch (error) {}
};
export const documentAttachmentUploadURLGen = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: headers(),
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            console.log(fileName, 'response');
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
            if (response.status === 200) return fileName;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {}
};
export const documentUploadAPIService = async (data, empId, props) => {
  try {
    return await axios({
      method: 'PUT',
      url: `${baseURL}/employee/${empId}/documents/`,
      headers: headers(),
      data: data,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          console.log(res, 'Api');
          // if (res.data.status >= 200 && res.data.status < 400) {
          //   const url = res.data.data.signedUrl;
          //   const fileName = res.data.data.filename;
          //   const fileUrl = res.data.data.fileUrl;
          //   const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);

          //   if (response.status === 200) return fileName;
          //   triggerToastError(`File Upload failed. Try after Sometimes.`);
          // }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {}
};
export const fileAttachmentDownloadURLGen = async (fileName, props) => {
  // const encodedFileName = encodeURIComponent(fileName);
  let signedUrl;
  try {
    await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: headers(),
      data: {
        filename: fileName,
        objectType: 'close',
        apiType: 'download',
      },
    })
      .then((res) => {
        if (res.data.status >= 200 && res.data.status < 400) {
          signedUrl = res.data.data.signedUrl;
        }
      })
      .catch((error) => {
        errormsg(error, props);
      });
  } catch (error) {
    // console.log(error, 'error');
  }
  return signedUrl;
};
export const fileAttachmentDownload = async (url, fileType, fileName, props) => {
  console.log(url, fileType, 'fileAttachmentDownload service');
  try {
    await axios({
      method: 'GET',
      url: `${url}`,
      responseType: 'arraybuffer',
    })
      .then((res) => {
        if (res.status >= 200) {
          if (res.data) {
            const blob = new Blob([res.data], { type: fileType });
            console.log(res);

            saveAs(blob, fileName);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {
    // dispatch(addOverlayLoading(false));
    console.log(error);
    // console.log(error.res, 'error');
  }
};
export const fileAttachmentDownload1 = (url, fileType, props) => async (dispatch, getState) => {
  console.log(url, fileType, 'fileAttachmentDownload service');
  try {
    await axios({
      method: 'GET',
      url: `${url}`,
      responseType: 'arraybuffer',
    })
      .then((res) => {
        if (res.status >= 200) {
          if (res.data) {
            const blob = new Blob([res.data], { type: fileType });
            console.log(res);

            saveAs(blob);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {
    // dispatch(addOverlayLoading(false));
    console.log(error);
    // console.log(error.res, 'error');
  }
};
export const awardsAPIService = async (data, empId, props) => {
  try {
    return await axios({
      method: 'PUT',
      url: `${baseURL}/employee/${empId}/awards/`,
      headers: headers(),
      data: data,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          console.log(res, 'Api');
          // if (res.data.status >= 200 && res.data.status < 400) {
          //   const url = res.data.data.signedUrl;
          //   const fileName = res.data.data.filename;
          //   const fileUrl = res.data.data.fileUrl;
          //   const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);

          //   if (response.status === 200) return fileName;
          //   triggerToastError(`File Upload failed. Try after Sometimes.`);
          // }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {}
};
export const certificationsAPIService = async (data, empId, props) => {
  try {
    return await axios({
      method: 'PUT',
      url: `${baseURL}/employee/${empId}/certifications/`,
      headers: headers(),
      data: data,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          console.log(res, 'Api');
          // if (res.data.status >= 200 && res.data.status < 400) {
          //   const url = res.data.data.signedUrl;
          //   const fileName = res.data.data.filename;
          //   const fileUrl = res.data.data.fileUrl;
          //   const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);

          //   if (response.status === 200) return fileName;
          //   triggerToastError(`File Upload failed. Try after Sometimes.`);
          // }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {}
};
export const deleteFileAttachmentAPIService = async (Id, props) => {
  try {
    return await axios({
      method: 'DELETE',
      url: `${baseURL}/employee/documents/${Id}`,
      headers: headers(),
    })
      .then(async (res) => {
        if (res.status >= 200) {
          console.log(res, 'Api');
          triggerToastSuccess(res && res.data && res.data.data);
          return res.data;
          // if (res.data.status >= 200 && res.data.status < 400) {
          //   const url = res.data.data.signedUrl;
          //   const fileName = res.data.data.filename;
          //   const fileUrl = res.data.data.fileUrl;
          //   const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);

          //   if (response.status === 200) return fileName;
          //   triggerToastError(`File Upload failed. Try after Sometimes.`);
          // }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {}
};

export const fileAttachmentUpload = async (file, props, url, fileName, fileUrl) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      axios
        .request({
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': file?.type,
          },
          method: 'PUT',
          data: reader.result,
          url: url,
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            // const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100 - 10;
          },
        })
        .then((response) => {
          if (response.status === 200) triggerToastSuccess(`File Uploaded Successfully`);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
          errormsg(err);
        });
    };
    reader.onerror = (err) => {
      err = { error: { message: 'file read error' } };
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getProfilePic = async (fileName) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${baseURL}/file-storage/generate-url-to-get?file_name=${encodeURIComponent(fileName)}`,
      headers: headers(),
    })
      .then((result) => {
        return resolve(result.data?.data?.signedUrl);
      })
      .catch((err) => {
        errormsg(err);
      });
  });
};
export const downloadDocument = async (file, props) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${baseURL}/file-storage/get-signed-url/`,

      headers: headers(),
      data: file,
    })
      .then((result) => {
        return resolve(result.data?.data?.signedUrl);
      })
      .catch((err) => {
        errormsg(err, props);
      });
  });
};
export const createcompoff = async (meth, day, data) => {
  const config = {
    method: meth,
    url: `${baseURL}${data}`,
    headers: headers(),
    data: day,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getcompoff = async (data) => {
  const config = {
    method: 'get',
    url: `${baseURL}/attendance/get-comp-off/status/${data}/`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const leavemapping = async (meth, data, path) => {
  const config = {
    method: meth,
    url: `${baseURLADMIN}/masters/${path}`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response?.data?.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getExecutiveByPincode = (path, labId, pincode) => {
  const config = {
    method: 'post',
    url: `${baseURL}${path}`,
    headers: headers(),
    data: {
      pageNumber: 0,
      searchCriteria: {
        labId: labId,
        pincode: pincode,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const getLabAvailableSlotsByDate = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const bookingReschedule = (path, data) => {
  if (data && data?.date?.startTime && data.labId && data.pincode) {
    const config = {
      method: 'post',
      url: `${baseURL}${path}`,
      headers: headers(),
      data: data,
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        triggerToastError(`${error.response.data.message}`);
        return error.response;
      });
  } else {
    triggerToastError(`Please choose date and time for reschedule`);
  }
};
export const getLabBookingDetails = (path) => {
  const config = {
    method: 'get',
    url: `${baseURL}${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error?.response?.data?.message}`);
      return error.response;
    });
};
export const getConsulationMail = () => {
  const config = {
    method: 'get',
    url: `${baseURL}/patients/consultations/get-consult/send-through/trigger/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return error.response;
    });
};
export const labBookingTestResults = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error?.response?.data?.message}`);
      return error.response;
    });
};
export const getdashborad = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/profiles/all-option/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const postdashboard = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/profiles/find-by-details/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const patientRegistrationDataService = (path, data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/profiles/registrationtotal/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const countofdoc = (path, data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/consultations/appointment-speciality/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const countappoint = (path, data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/consultations/appointment-counts/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createSubcripcion = (path, day, data, props) => {
  const config = {
    method: day,
    url: `${baseURLADMIN}${path}`,
    headers: headersADMIN(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const countlab = (path, data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/hrms/laboratory/laboratory/labtotalcount/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const numberofpa = (data) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/profiles/patient-counts/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const awardsdetails = (data, emd) => {
  const config = {
    method: 'put',
    url: `${baseURL}/employee/${emd}/awards/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
};
export const awardsdelete = (data, emd) => {
  const config = {
    method: 'delete',
    url: `${baseURL}/employee/${emd}/awards/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
};
export const certificatedetails = (data, emd) => {
  const config = {
    method: 'put',
    url: `${baseURL}/employee/${emd}/certifications/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
};
export const documentdetails = (data, emd) => {
  const config = {
    method: 'put',
    url: `${baseURL}/employee/${emd}/documents/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data.data.filename;
    })
    .catch(function (error) {
      return error.response;
    });
};
export const leaverequest = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/attendance/leave-requests/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getleavemapping = async (meth, data) => {
  const config = {
    method: meth,
    url: `${baseURLADMIN}/masters${data}`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getfreedoctor = async (path, data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getpharmacyorder = async (status, pageNumber, itemPerPage, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/get/codOrders/?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
    // data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const leaverequesthistroy = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/attendance/leave-requests/${id}/`,
    headers: headers(),
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const leaverequesthistroydatefilter = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/attendance/emp/leave-requests/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getApprovalLeaves = async (id, props) => {
  const data = {
    itemsPerPage: 0,
    pageNumber: 0,
    searchCriteria: {
      status: 'Pending',
      statusBy: id,
    },
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/attendance/leave-request/filter/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getHrleave = async (id, props) => {
  const data = {
    itemsPerPage: 0,
    pageNumber: 0,
    searchCriteria: id,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/attendance/leave-request/filter/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTeamLeavesHis = async (id, data, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/attendance/teams-leave-requests/${id}/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const allleaverequest = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/referrals/all-team-member?returnId=true`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getDrugs = async (data, itemPerPage, pageNumber, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/pharmacy/drugs/all/list/`,
    headers: headers(),
    data: { searchCriteria: data, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAllLeavesHis = async (data) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/attendance/leave-request/filter/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const createLeaveBalance = async (data, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/attendance/leave-balances/`,
    headers: headers(),
    data: [data],
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createleavemaster = async (data) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/attendance/leave-code/`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const createholiday = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/attendance/holidays/`,
    headers: headersADMIN(),
    data: [data],
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const editleavemaster = async (id, data, props) => {
  const config = {
    method: 'put',
    url: `${baseURLADMIN}/masters/attendance/leave-code/${id}/`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const editholiday = async (id, data, props) => {
  const config = {
    method: 'put',
    url: `${baseURLADMIN}/masters/attendance/holidays/${id}/`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const documentPreration = async (data, path, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getdocuments = async (path, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}${path}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      // triggerToastSuccess(response?.data?.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const dietary = async (data, path, meth, props) => {
  const config = {
    method: meth,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getdiet = async (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/caretakers/caretaker/dietary-food-items/list`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getempifo = async (data, itemPerPage, pageNumber, props) => {
  console.log(data, itemPerPage, pageNumber);

  const config = {
    method: 'post',
    url: `${baseURL}/all/list/?&basic=true`,
    headers: headers(),
    data: { searchCriteria: data, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getemppagenation = async (data, datass2, pageNumber, itemPerPage, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/all/list/`,
    headers: headers(),
    data: { searchCriteria: data, findCriteria: datass2, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const fileAttachmentDownloadObject = async (fileName, props) => {
  // const encodedFileName = encodeURIComponent(fileName);
  let signedUrl;
  try {
    await axios({
      method: 'POST',
      url: `${baseURL}/file-storage/get-signed-url/`,
      headers: headers(),
      data: {
        filename: fileName,
        objectType: 'close',
        apiType: 'download',
      },
    })
      .then((res) => {
        if (res.data.status >= 200 && res.data.status < 400) {
          signedUrl = res.data.data;
        }
      })
      .catch((error) => {
        errormsg(error, props);
      });
  } catch (error) {
    // console.log(error, 'error');
  }
  return signedUrl;
};
export const deleteholiday = async (id, props) => {
  const config = {
    method: 'delete',
    url: `${baseURLADMIN}/masters/attendance/holidays/${id}/`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const deleteleavemaster = async (id, props) => {
  const config = {
    method: 'delete',
    url: `${baseURLADMIN}/masters/attendance/leave-code/${id}`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      // return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const masterleavecode = async (props) => {
  const config = {
    method: 'get',
    url: `${baseURLADMIN}/masters/attendance/leave-code/`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const holiday = async (s, e, props) => {
  const config = {
    method: 'get',
    url: `${baseURLADMIN}/masters/attendance/holidays/${s}/${e}/`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getholiday = async () => {
  const config = {
    method: 'get',
    url: `${baseURLADMIN}/masters/attendance/holidays/`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getLeaveBalanceByEmployee = async (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/attendance/get-leave-balances/${id}/`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      if (response.data.data === false) {
        triggerToastError(`No Leave Balance is Available`);
      }
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const leavebalance = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/attendance/get-leave-balances/${id}/`,
    headers: headers(),
  };
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const cancelleaverequest = (id) => {
  const empId = localStorage.getItem('empId');
  const data = {
    leaveId: id,
    actionBy: empId,
  };
  const config = {
    method: 'PUT',
    url: `${baseURL}/attendance/leave-request/cancel/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      leaverequesthistroy(empId);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const approveOrRejectleaverequest = (id, status, props) => {
  const empId = localStorage.getItem('empId');
  const data = {
    leaveId: id,
    actionBy: empId,
  };
  const config = {
    method: 'PUT',
    url: `${baseURL}/attendance/leave-request/change-status/${status}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      getApprovalLeaves(empId);
      triggerToastSuccess(response && response.data && response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const createTeamOwnerService = async (id, props) => {
  const data = {
    owner: id,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/referrals/teams/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      triggerToastSuccess(response?.data?.message);
      return response.data.status;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTeamOwnersService = async (states, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/referrals/teams/${states}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTeamName = async (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/referrals/teams/`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const searchcategoryitem = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/find/category-items/`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getEmpnamebyTeam = async (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/referrals/team-members/${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getshiftbyempid = async (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/roaster/empshifts/${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getshiftbyteamid = async (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/roaster/teamshifts/${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getemployeeShiftbyteam = async (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/roaster/teamshifts/${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const employeeandteamShiftdata = async (id) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/roaster/teamshifts/${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const Reportingtoemployee = async (id, props) => {
  const config = {
    method: 'POST',
    url: `${baseURL}/reporting-to/person/`,
    headers: headers(),
    data: id,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const findemployeeinteamornot = async (id, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/referrals/team/findemployee`,
    headers: headers(),
    data: id,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAssignShift = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/roaster/employeeshiftmappings`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response?.data?.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const deleteshift = async (data, props) => {
  const config = {
    method: 'delete',
    url: `${baseURL}/roaster/employeeshiftmappings`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response?.data?.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAssignShiftedit = async (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/roaster/employeeshiftmappings`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response?.data?.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getShiftroute = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/roaster/employeeshiftmappings`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response?.data?.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getEditshift = async (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/roaster/empshifts/${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const viewShift = async (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/roaster/detail/listshifts`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getorderStatus = async (id) => {
  const config = {
    method: 'GET',
    url: `${baseURL}${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getapproveddata = async (props, pageNumber, itemPerPage, startDate, endDate, ui) => {
  let date = '';
  if (ui === 'search') {
    const url = `&startDate=${startDate}&endDate=${endDate}`;
    date = url;
  }
  const config = {
    method: 'GET',
    url: `${baseURL}/home-collection/cod/consultation/get/all-orders/?pageNumber=${pageNumber}&totalSize=${itemPerPage}${date}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const poatapproveddata = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/home-collection/cod/consultation/orders/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const canceldataorder = async (data, id, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/home-collection/cod/consultation/set-status/${id}/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const canceldataorderinassignpage = async (data, id, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/patients/consultations/${id}/cancel-consultation`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const Attendancesummery = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/attendance-summary/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const poatapproveddatapaid = async (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/home-collection/cod/consultation/payment-complete/?payLater=true`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const approveCodorder = async (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/home-collection/cod/consultation/payment-complete/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getdataforcoins = async (props) => {
  const config = {
    method: 'get',
    url: `${baseURLADMIN}/masters/coins/master-data/`,
    headers: headersADMIN(),
    // data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getSubscription = async (props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/subscription/names/subscriptions/`,
    headers: headers(),
    // data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getCoinsdata = async (data, meth, path, props) => {
  const config = {
    method: meth,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getCoinsRule = async (data, meth, path, props) => {
  const config = {
    method: meth,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const drugTypedata = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/findall`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const subdruge = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/pharmacy/drugs/all/list/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      if (response && response.data && response.data.code === 401) {
        props.history.push({
          pathname: '/login',
          state: {},
        });
      }
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getOrderbystatus = async (data) => {
  const config = {
    method: 'post',
    url: `${baseURL}/home-collection/cod/consultation/get-orders/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getOrderbystatus1 = async (data, pageNumber, itemPerPage, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/home-collection/cod/consultation/get-orders/all/list/`,
    headers: headers(),
    data: { searchCriteria: data, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const homecollectioncoddepositdata = async (data, pageNumber, itemPerPage, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/home-collection/cod/deposit/all/list/`,
    headers: headers(),
    data: { searchCriteria: data, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateleavebalance = async (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/attendance/update/leave-balance/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getLabExecutive = async (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/home-collection/executive/${id}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getIfcecode = async (id, props) => {
  const config = {
    method: 'GET',
    url: `https://ifsc.razorpay.com/${id}`,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const updateReferralStatus = async (_d, path, method, props) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseURL}${path}`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const finddoc = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/listDoctors/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const consultationStatus = async (props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/patients/consultations/analytics/consultation-status`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const consultationDataList = async (status, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/patients/consultations/analytics/consultation-detail/${status}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTeamsGlobalEmployee = async (empId, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/referrals/get-employee-list/member/?owner=${empId}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTeamsSelectedEmployee = async (team, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/referrals/team-members/${team}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getselectcity = async (city, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/masters/places/search/map?query=${city}`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getempshift = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/roaster/findshifts/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getshiftemp = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURL}/roaster/findemployees/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getbanners = async (data, props) => {
  const config = {
    method: 'post',
    url: `${baseURLADMIN}/masters/categories/findall`,
    headers: headersADMIN(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
      searchCriteria: {
        categoryType: data,
        isActive: true,
      },
      sortInstruction: {
        attributeName: 'priority',
        sortDirection: 1,
      },
    },
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const postbanner = async (method, data, props) => {
  const config = {
    method: method,
    url: `${baseURLADMIN}/banners/`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const pharmacyName = async () => {
  const config = {
    method: 'get',
    url: `${baseURL}/pharmacy/pharmacy/`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const deletebanner = async (data) => {
  const config = {
    method: 'delete',
    url: `${baseURLADMIN}/banners/`,
    headers: headersADMIN(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getpack = async () => {
  const config = {
    method: 'post',
    url: `${baseURL}/laboratory/packages/all/list/`,
    headers: headers(),
    data: {},
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const gettest1 = async () => {
  const config = {
    method: 'GET',
    url: `${baseURL}/laboratory/lab-masters/tests/`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getimage = async (props) => {
  const config = {
    method: 'GET',
    url: `${baseURLADMIN}/banners`,
    headers: headersADMIN(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTimeTrackingService = async (empId, start, end, props) => {
  // start.setHours(0, 0, 0, 0);
  // end.setHours(0, 0, 0, 0);
  const data = {
    employeeId: empId,
    startDate: start,
    endDate: end,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/attendance-summary/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAttendanceService = async (empId, date, props) => {
  const data = {
    employeeId: empId,
    date: date,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/emp/working/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getDepAttendanceService = async (dep, startDate, props) => {
  const data = {
    Date: startDate,
    departmentName: dep,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/speciality/working-hour/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateTeams = async (data, teamId, method, props) => {
  let pathUrl;
  if (method === 'PUT') {
    pathUrl = teamId + '/';
  } else {
    pathUrl = '';
  }
  const config = {
    method: method,
    url: `${baseURL}/referrals/team-members/${pathUrl}`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response && response.data && response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAllCodPincodesService = (props) => {
  const data = {
    searchCriteria: {
      isActive: true,
    },
  };
  const config = {
    method: 'POST',
    url: `${baseURLADMIN}/cod-pincodes/all/list/`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const createCodPincodeService = (data, props) => {
  // const data = {
  //   searchCriteria: {
  //     isActive: true,
  //   },
  // };
  const config = {
    method: 'POST',
    url: `${baseURLADMIN}/cod-pincodes/`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response && response.data && response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response.data;
    });
};
export const updateCodPincodeService = (data, id, props) => {
  // const data = {
  //   searchCriteria: {
  //     isActive: true,
  //   },
  // };
  const config = {
    method: 'PUT',
    url: `${baseURLADMIN}/cod-pincodes/${id}/`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response && response.data && response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response.data;
    });
};
export const deleteCodPincodeService = (id, props) => {
  // const data = {
  //   searchCriteria: {
  //     isActive: true,
  //   },
  // };
  const config = {
    method: 'DELETE',
    url: `${baseURLADMIN}/cod-pincodes/${id}/`,
    headers: headersADMIN(),
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess(response && response.data && response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response.data;
    });
};

export const getPatientInvoicePDFService = async (Id, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/generate/invoice/${Id}/`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data.signedUrl;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientPharmacyPaidOrderList = (status, pageNumber, itemPerPage, props) => {
  const payload = {
    orderBy: 'orderDate',
    isAscending: false,
    pageNumber: pageNumber,
    totalSize: itemPerPage,
    searchCriteriaList: status,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/search-order/`,
    headers: headers(),
    data: payload,
  };

  return axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientPharmacyNotPaidOrderList = (pageNumber, itemPerPage, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/get-by/payment-status/Not Paid/?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientConsultationPaidOrderList = (status, pageNumber, itemPerPage, props) => {
  // let date = '';
  // if (ui === 'search') {
  //   const url = `&startDate=${startDate}&endDate=${endDate}`;
  //   date = url;
  // }
  const payload = {
    orderBy: 'orderDate',
    isAscending: false,
    pageNumber: pageNumber,
    totalSize: itemPerPage,
    searchCriteriaList: status,
  };
  console.log(status, pageNumber, itemPerPage, props);

  const config = {
    method: 'POST',
    url: `${baseURL}/patients/consultations/orders/search-order/`,
    headers: headers(),
    data: payload,
  };

  return axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientConsultationNotPaidOrderList = (pageNumber, itemPerPage, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/patients/consultations/orders/get-by/payment-status/Not Paid/?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientLabPaidOrderList = (status, pageNumber, itemPerPage, props) => {
  const payload = {
    orderBy: 'orderDate',
    isAscending: false,
    pageNumber: pageNumber,
    totalSize: itemPerPage,
    searchCriteriaList: status,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/laboratory/laboratory/orders/search-order/`,
    headers: headers(),
    data: payload,
  };

  return axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPatientLabNotPaidOrderList = (pageNumber, itemPerPage, props) => {
  const config = {
    method: 'GET',
    url: `${baseURL}/laboratory/laboratory/orders/get-by/payment-status/Not Paid/?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendPaymentLinkService = async (data, props) => {
  const config = {
    method: 'PUT',
    url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/update/order/?link=true`,
    headers: headers(),
    data,
  };

  return await axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      triggerToastSuccess('Payment Link Sent Successfully');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendPaymentLinkConsultationService = async (data, props) => {
  const config = {
    method: 'PUT',
    url: `${baseURL}/patients/payment-gateway/consultationOrder/payment/pay/?link=true`,
    headers: headers(),
    data,
  };

  return await axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      triggerToastSuccess('Payment Link Sent Successfully');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendPaymentLinkLabService = async (data, props) => {
  const config = {
    method: 'PUT',
    url: `${baseURL}/patients/payment-gateway/labOrder/payment/pay/?link=true`,
    headers: headers(),
    data,
  };

  return await axios(config)
    .then(function (response) {
      // const msg = response.data.message;
      // const message =
      //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      // triggerToastSuccess(message);
      triggerToastSuccess('Payment Link Sent Successfully');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const attendanceEdit = async (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/regulations/working-hour/`,
    headers: headers(),
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getLabOrderHistory = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURLLAB}/laboratory/orders/get/history/${id}/`,
    headers: headerslab(),
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getConsulationOrderHistory = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/patient-service/consultations/orders/get/history/${id}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPharmacyOrderHistory = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURLPHARMACY}/orders/patient-pharmacy-order/get/history/${id}`,
    headers: headerphar(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPaymentGateWayHistory = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/patient-service/proxy-payment/payment/v2/order-id/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const changeNotPaidtopaid = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/patient-service/proxy-payment/payment/v2/manual-transaction-sync/${id}`,
    headers: headersPatient(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const changeConsulationOrderStatustoCancell = (data, props) => {
  const config = {
    method: 'put',
    url: `${baseURL}/patients/consultations/unpaid/cancelled/`,
    headers: headersPatient(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getBookingsById = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseURL}/laboratory/laboratory/bookings/get/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getBookingsStatusById = (id, props) => {
  const data = {
    bookingId: id,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/home-collection/bookings/stat/get/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendPatientMessage = (data, path, props) => {
  const config = {
    method: 'POST',
    url: `${baseURLADMIN}${path}`,
    headers: headersADMIN(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getOrganisationDataService = async (id, props) => {
  const data = {
    empId: id,
  };
  const config = {
    method: 'POST',
    url: `${baseURL}/organization-chart/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
