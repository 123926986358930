export const pharmaReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_DRUG_DATA':
      return {
        ...state,
        [action.name]: action.payload,
      };
    case 'GET_SHIFTTIMEMAP_DATA':
      return {
        ...state,
        shiftTimeMap: action.payload,
      };
    case 'GET_PATIENTPHARMACYPAIDORDER_LIST':
      return {
        ...state,
        patientPharmacyPaidOrderList: action.payload,
      };
    case 'GET_PHARMACYCOD_LIST':
      return {
        ...state,
        patientPharmacyCODOrderList: action.payload,
      };
    case 'GET_PATIENTPHARMACYNOTPAIDORDER_LIST':
      return {
        ...state,
        patientPharmacyNotPaidOrderList: action.payload,
      };
    case 'GET_PATIENTLABPAIDORDER_LIST':
      return {
        ...state,
        patientLabPaidOrderList: action.payload,
      };
    case 'GET_PATIENTLABNOTPAIDORDER_LIST':
      return {
        ...state,
        patientLabNotPaidOrderList: action.payload,
      };
    case 'GET_PATIENTCONSULTATIONPAIDORDER_LIST':
      return {
        ...state,
        patientConsultationPaidOrderList: action.payload,
      };
    case 'GET_PATIENTCONSULTATIONNOTPAIDORDER_LIST':
      return {
        ...state,
        patientConsultationNotPaidOrderList: action.payload,
      };
    default:
      return state;
  }
};
export default pharmaReducer;
