import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import PropTypes from 'prop-types';
// import cellEditFactory from 'react-bootstrap-table2-editor';

const { SearchBar } = Search; // ClearSearchButton
// const { ExportCSVButton } = CSVExport;
function RBTableNext({
  data,
  columns,
  key,
  selectRow,
  headerStart,
  page,
  pageName,
  setPageNumber,
  setItemPerPage,
  totalPage,
}) {
  // console.log(totalPage, 'totalPage');
  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    page: page,
    totalSize: totalPage ?? data?.length,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    onSizePerPageChange: (sizePerPage, page) => {
      if (totalPage) {
        console.log('Size per page change!!!');
        console.log('Newest size per page:' + sizePerPage);
        console.log('Newest page:' + page);
        setPageNumber(page);
        setItemPerPage(sizePerPage);
      }
    },
    onPageChange: (page, sizePerPage) => {
      if (totalPage) {
        console.log('Page change!!!');
        console.log('Newest size per page:' + sizePerPage);
        console.log('Newest page:' + page);
        setPageNumber(page);
        setItemPerPage(sizePerPage);
      }
    },
    sizePerPageList:
      pageName === 'profile'
        ? [
            {
              text: '15',
              value: 15,
            },
            {
              text: '50',
              value: 50,
            },
            {
              text: '100',
              // value: totalPage <= 20 ? totalPage : 20 || data?.length,
              value: totalPage <= 100 ? totalPage : 100 || data?.length,
            },
          ]
        : [
            {
              text: '5',
              value: 5,
            },
            {
              text: '10',
              value: 10,
            },
            {
              text: '20',
              // value: totalPage <= 20 ? totalPage : 20 || data?.length,
              value: totalPage <= 20 ? totalPage : 20 || data?.length,
            },
          ], // A numeric array is also available. the purpose of above example is custom the text
  };
  const onTableChange = (type, newState) => {
    console.log(type);
    console.log(newState);
  };
  return (
    <ToolkitProvider keyField="id" data={data} columns={columns} search exportCSV>
      {(props) => {
        console.log(props, 'tableprops');
        return (
          <div>
            <div className="table_utils d-flex f-wrap" style={{ placeContent: 'flex-end' }}>
              {headerStart}
              {!totalPage && (
                <>
                  <div className="form-check form-check-primary flex-1 m-0 my-1"></div>
                  <SearchBar className="my-1" {...props.searchProps} />
                  {/* <ClearSearchButton className="btn bg-gradient-danger mx-2 my-1 btn-sm" {...props.searchProps} /> */}
                  <button
                    className="btn bg-gradient-danger btn-sm p-1 px-2 my-2 ml-1"
                    style={{ fontSize: '10px', height: 30 }}
                    onClick={() => props.searchProps.onClear()}
                  >
                    <span style={{ fontSize: 14 }}>X</span>
                  </button>
                </>
              )}
              {/* <ExportCSVButton className="btn btn-outline-success btn-sm my-1" {...props.csvProps}>
              <i className="mdi mdi-file-excel"></i>
            </ExportCSVButton> */}
            </div>
            <div>
              {
                <BootstrapTable
                  {...props.baseProps}
                  key={key}
                  remote={!!totalPage || totalPage === 0}
                  bootstrap4
                  pagination={paginationFactory(options)}
                  defaultSorted={defaultSorted}
                  className="table-sm"
                  selectRow={selectRow}
                  onTableChange={onTableChange}
                  wrapperClasses="table table-responsive table-striped table-hover"
                />
              }
            </div>
          </div>
        );
      }}
    </ToolkitProvider>
  );
}
RBTableNext.propTypes = {
  searchProps: PropTypes.any,
  baseProps: PropTypes.any,
  csvProps: PropTypes.any,
  data: PropTypes.any,
  columns: PropTypes.any,
  key: PropTypes.any,
  options: PropTypes.any,
  defaultSorted: PropTypes.any,
  selectRow: PropTypes.any,
  headerStart: PropTypes.any,
  page: PropTypes.any,
  pageName: PropTypes.any,
  setPageNumber: PropTypes.any,
  setItemPerPage: PropTypes.any,
  totalPage: PropTypes.any,
};

export default RBTableNext;
