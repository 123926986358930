/* eslint-disable react/prop-types */
import React from 'react';
// import CsvDownload from 'react-json-to-csv';
import ExportJsonCsv from './JsonFunction';

function JsontoCsv(props) {
  console.log(props.csvdata);
  console.log(props);
  const arr = props.csvdata && props.csvdata.length > 0 && props.csvdata[0];
  const keys = Object.keys(arr);
  console.log(keys);

  const headers =
    keys &&
    keys.length > 0 &&
    keys.map((val) => ({
      key: val,
      name: val,
    }));

  return (
    <ExportJsonCsv
      headers={headers}
      style={{
        boxShadow: 'inset 0px 1px 0px 0px #e184f3',
        background: 'linear-gradient(to bottom, #c123de 5%, #a20dbd 100%)',
        borderRadius: '6px',
        border: '1px solid #a511c0',
        display: 'inline-block',
        cursor: 'pointer',
        color: '#ffffff',
        fontSize: '15px',
        fontWeight: 'bold',
        //   padding: '6px 24px',
        textDecoration: 'none',
        textShadow: '0px 1px 0px #9b14b3',
      }}
      items={props.csvdata}
      fileTitle={props.name}
    >
      <i className="mdi mdi-download"></i>
    </ExportJsonCsv>
    // <CsvDownload
    //   data={props.csvdata}
    //   filename={props.name}
    //   style={{
    //     boxShadow: 'inset 0px 1px 0px 0px #e184f3',
    //     background: 'linear-gradient(to bottom, #c123de 5%, #a20dbd 100%)',
    //     borderRadius: '6px',
    //     border: '1px solid #a511c0',
    //     display: 'inline-block',
    //     cursor: 'pointer',
    //     color: '#ffffff',
    //     fontSize: '15px',
    //     fontWeight: 'bold',
    //     //   padding: '6px 24px',
    //     textDecoration: 'none',
    //     textShadow: '0px 1px 0px #9b14b3',
    //   }}
    // >
    //   <i className="mdi mdi-download"></i>
    // </CsvDownload>
  );
}

export default JsontoCsv;
