import { toast } from 'react-toastify';

export const getToken = () => {
  return localStorage.getItem('accessToken');
};
export const removeToken = () => {
  localStorage.clear();
};
export const getloginDuration = () => {
  const time = localStorage.getItem('loginDuration');
  const sec = time * 60000;
  // console.log(sec, 'sec');
  return sec;
};
export const getUsername = () => {
  return localStorage.getItem('username');
};
export const getfunctions = () => {
  return localStorage.getItem('functions');
};
export const getFirstlogstatus = () => {
  return localStorage.getItem('firstlog');
};
export const getRole = () => {
  return localStorage.getItem('roles');
};
export const EmailValidation = (mail) => {
  console.log(mail);
  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mail.match(mailformat)) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
};
export const handleWarnAlert = (reason) => {
  toast.warn(reason, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const handleDangerAlert = (reason) => {
  toast.error(reason, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const leaveInfo = [
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-20T00:00:00Z',
    endDate: '2022-07-20T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-22T00:00:00Z',
    endDate: '2022-07-22T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
  {
    startDate: '2022-07-19T00:00:00Z',
    endDate: '2022-07-19T00:00:00Z',
    requestorComments: 'CL',
    leaveType: 'Casual Leave',
  },
];
