/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable lines-between-class-members */
import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import logo from '../assets/images/Diahome_logo-removebg-white 1.png';
import LogoMini from '../assets/images/Diahome-removebg-preview.png';
import avatar from '../assets/images/aever.png';
import { Link } from 'react-router-dom';
import { removeToken, getUsername, getloginDuration } from '../utils/utils';
import PropTypes from 'prop-types';
import Geocode from 'react-geocode';
import Timer from 'react-compound-timer';
import Swal from 'sweetalert2';
import { logout } from '../services/services';
import RecordDate from '../pages/dashboard/RecordDate';
import { logoutAction } from '../redux/actions/actions';
import { connect } from 'react-redux';

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
Geocode.setApiKey(googleMapKey);
Geocode.setLanguage('en');
Geocode.setRegion('es');
// Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();
const mapDispatchToProps = {
  logoutAction,
};
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  timedata(getTime) {
    const day = getTime();
    const set = day / 60000;
    const stroe = localStorage.setItem('loginDuration', set);
    // console.log(day, set, stroe);
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  // getLatLog(lat, lng) {
  //   Geocode.fromLatLng(lat, lng).then(
  //     (response) => {
  //       this.state.add(response.results[0].formatted_address);
  //       console.log(response.results[0].formatted_address);
  //       // const address = response.results[0].formatted_address;
  //       // console.log(address, 'address');
  //     },
  //     (error) => {
  //       console.error(error, 'googleApi error');
  //       this.state.add('');
  //     },
  //   );
  // }
  navegator(data, propsdata) {
    data.getCurrentPosition(function (position) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      console.log(lat, lng);
      Geocode.fromLatLng(lat, lng).then(
        async (response) => {
          const addressComponents = response.results[0].address_components;
          console.log(response.results[0].formatted_address);
          let country;
          let pincode;
          let state;
          let city;
          let area;
          // eslint-disable-next-line array-callback-return
          addressComponents.map((countryId) => {
            if (countryId.types[0] && countryId.types[0] === 'country') {
              country = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'postal_code') {
              pincode = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'administrative_area_level_1') {
              state = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'administrative_area_level_2') {
              city = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'locality') {
              area = countryId.long_name;
            }
          });
          const address = `${city || ''}, ${area || ''}, ${state || ''}, ${country || ''} - ${pincode || ''}`;
          const emp = localStorage.getItem('username');
          const tok = localStorage.getItem('accessToken');
          const data = {
            username: emp,
            location: address,
          };
          if (emp && tok) {
            console.log(propsdata);
            propsdata.logoutAction(data);

            propsdata.props.history.push('/login');
            removeToken();
          }
        },
        (error) => {
          console.error(error, 'googleApi error');
        },
      );
    });
  }
  signOut(e) {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to logout!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#fff',
      cancelButtonColor: '#fff',
      confirmButtonText: 'Yes, Logout',
    }).then((result) => {
      if (result.value) {
        if (navigator.geolocation) {
          this.navegator(navigator.geolocation, this.props);
        }
      }
    });
  }

  render() {
    return (
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/dashboard">
            <img src={LogoMini} alt="logo" />
          </Link>
          <RecordDate />
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle('sidebar-icon-only')}
          >
            <span className="mdi mdi-menu text-white"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link">
                  <div className="nav-profile-img">
                    <img src={avatar} alt="user" />
                    <span className="availability-status online"></span>
                  </div>
                  <div className="nav-profile-text pt-5">
                    <p className="mb-1 text-white">{getUsername()}</p>
                    <div>
                      <p>
                        <Timer initialTime={getloginDuration()}>
                          {({ start, resume, pause, stop, reset, timerState, getTime }) => (
                            <React.Fragment>
                              <div>
                                {/* <Timer.Days /> days */}
                                <Timer.Hours />
                                h: <Timer.Minutes />m : <Timer.Seconds />s{/* <Timer.Milliseconds /> milliseconds */}
                              </div>
                              <div>{this.timedata(getTime)}</div>
                              <br />
                              {/* <div>
                            <button onClick={start}>Start</button>
                            <button onClick={pause}>Pause</button>
                            <button onClick={resume}>Resume</button>
                            <button onClick={stop}>Stop</button>
                            <button onClick={reset}>Reset</button>
                          </div> */}
                            </React.Fragment>
                          )}
                        </Timer>
                      </p>
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item href="/profile">
                    <i className="mdi mdi-account mr-2 text-primary"></i>
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="!#"
                    onClick={(evt) => {
                      this.signOut(evt);
                    }}
                  >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    Signout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}
Navbar.propTypes = {
  history: PropTypes.any,
  props: PropTypes.any,
};
export default connect(null, mapDispatchToProps)(Navbar);
