export const timeTrackingReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_TIMETRACKING_DATA':
      return {
        ...state,
        timeTrackingData: action.payload,
      };
    case 'GET_TRACK_DATA':
      return {
        ...state,
        timeTrack: action.payload,
      };
    case 'GET_ATTENDANCE_DATA':
      return {
        ...state,
        attendanceData: action.payload,
      };
    case 'GET_DEPARTMENT_ATTENDANCE_DATA':
      return {
        ...state,
        depAttendanceData: action.payload,
      };
    default:
      return state;
  }
};
export default timeTrackingReducer;
